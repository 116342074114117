'use client';

import React, {useEffect} from 'react';
import {INavigation} from '@/app/navigation/Navigation.types';
import Link from 'next/link';
import ChevronDown from '@/images/icons/chevron-down.svg';
import FlyOutNavigation from '@/app/navigation/main/FlyOutNavigation';
import {useDispatch} from 'react-redux';
import {setBackdropVisible} from '@/redux/slices/layoutSlice';
import {useState} from 'react';
import MegaMenuNavigation from '@/app/navigation/main/MegaMenuNavigation';
import ClientOnly from '@/app/components/util/ClientOnly/ClientOnly';
import {usePathname, useSearchParams} from 'next/navigation';

interface MainNavigationItemProps {
    page: INavigation;
    productRequestPage?: string;
    productOverviewPage?: string;
}

export default function MainNavigationItem({page, productRequestPage, productOverviewPage}: MainNavigationItemProps) {
    const pathname = usePathname();
    const [open, setOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const hasChildren = page.children && page.children.filter(p => p.visible).length > 0;
    const isMegaMenu = hasChildren && page.customProperties.title && page.customProperties.teaserText;
    const searchParams = useSearchParams()

    const handleMouseEnter = () => {
        if (!hasChildren) {
            return;
        }

        dispatch(setBackdropVisible(true));
        setOpen(true);
    }

    const handleMouseLeave = () => {
        if (!hasChildren) {
            return;
        }

        dispatch(setBackdropVisible(false));
        setOpen(false);
    }

    useEffect(() => {
        setOpen(false);
        dispatch(setBackdropVisible(false));
    }, [pathname, searchParams]);

    return (
        <li onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
        >
            <Link
                href={page.href}
                className={`group h-20 flex text-sm font-bold mr-6 hover:text-blue-100 focus:text-blue-100 transition ${new RegExp(`^${page.href}.*$`).test(pathname) ? 'text-blue-100' : 'text-blue-200'}`}
                title={page.label}>
                <div className="my-auto h-6">
                    {page.label}
                    {hasChildren && (
                        <ChevronDown
                            className={`w-6 ml-0.5 -mt-0.5 h-6 inline group-hover:text-blue-100 transition ${page.active ? 'text-blue-100' : 'text-blue-200'}`}/>
                    )}
                </div>
            </Link>

            {isMegaMenu ? (
                <ClientOnly>
                    <MegaMenuNavigation
                        page={page}
                        open={open}
                        productRequestPage={productRequestPage}
                        productOverviewPage={productOverviewPage}
                    />
                </ClientOnly>
            ) : hasChildren && (
                <div className="relative">
                    <FlyOutNavigation pages={page.children ? page.children.filter(p => p.visible) : []} open={open}
                                      navLvl={2}/>
                </div>
            )}

        </li>
    )
}
