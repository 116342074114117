'use client';

import React, {useRef} from 'react'
import {Provider} from 'react-redux'
import {makeStore, AppStore} from './store'
import {initializeTranslationState, TranslationsState} from '@/redux/slices/translationsSlice';

interface StoreProviderProps {
    children: React.ReactNode;
    initialTranslationState: TranslationsState;
}


export default function StoreProvider({children, initialTranslationState}: StoreProviderProps) {
    const storeRef = useRef<AppStore>()

    if (!storeRef.current) {
        // Create the store instance the first time this renders
        storeRef.current = makeStore();
        storeRef.current?.dispatch(initializeTranslationState(initialTranslationState));
    }

    return <Provider store={storeRef.current}>{children}</Provider>
}
