'use client';

import React from 'react';
import {GroupedSearchItem} from '@/types/search';
import Results from '@/app/layout/search/Results';

interface ResultsGroupedProps {
    results: GroupedSearchItem[];
    searchQuery: string;
}

export default function ResultsGrouped({results, searchQuery}: ResultsGroupedProps) {

    return (
        <div>
            {results.map((group, index) => (
                <div className="mb-12" key={index}>
                    <Results initialHits={group.hits} isInGroup={true} searchQuery={searchQuery} itemCount={group.itemCount} subType={group.key} label={group.label} />
                </div>
            ))}
        </div>
    )
}
