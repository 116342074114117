'use client';

import {INavigation} from '@/app/navigation/Navigation.types';
import React from 'react';
import Link from "next/link";
import ChevronRight from '@/images/icons/chevron-right.svg';
import LinkExternal from '@/images/icons/link-external.svg';
import {productCategoryPath} from '@/util/helper/path';
import {usePathname} from 'next/navigation';

interface MegaMenuNavigationLinkProps {
    page: INavigation;
    withThumbnail: boolean;
    isActive: boolean;
    forceChevronIcon: boolean;
    productOverviewPage?: string;
}

export default function MegaMenuNavigationLink(
    {
        page,
        withThumbnail,
        isActive,
        forceChevronIcon,
        productOverviewPage
    }: MegaMenuNavigationLinkProps) {

    const pathname = usePathname();

    const href = page.id.startsWith('product_category') && productOverviewPage
        ? productCategoryPath(productOverviewPage, page.id.split('_').pop())
        : page.href;

    return (
        <Link
            href={href} target={page.target ?? '_self'}
            className={`${!withThumbnail ? 'h-12' : ''} flex -mx-2 mb-2 justify-center content-center`}
        >
            {withThumbnail && (
                <div className="w-16 min-w-16 px-2">
                    {page.customProperties.thumbnail ? (
                        <img className="w-12 h-12 object-cover rounded" src={page.customProperties.thumbnail}
                             alt={page.customProperties.title ?? page.label}/>
                    ) : (
                        <div className="w-12 h-12 rounded-md bg-grey-175"></div>
                    )}
                </div>
            )}
            <div className="flex-grow px-2 flex">
                <span
                    className={`${isActive || new RegExp(`^${page.href}$`).test(pathname) ? 'text-blue' : 'text-blue-200'} font-bold inline-block my-auto transition leading-tight`}>
                    {page.label}
                </span>
            </div>
            <div className="w-12 min-w-12 px-2 flex">
                {forceChevronIcon || !!page.children?.length ? (
                    <ChevronRight className="w-6 inline text-blue-100 -mt-1"/>
                ) : page.target === '_blank' && (
                    <LinkExternal className="w-6 inline text-blue-100 -mt-1"/>
                )}
            </div>
        </Link>
    )
}
