'use client';

import {INavigation} from '@/app/navigation/Navigation.types';
import React, {useEffect, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import MegaMenuSnippetContent from '@/app/navigation/main/MegaMenuSnippetContent';
import MegaMenuNavigationLink from '@/app/navigation/main/MegaMenuNavigationLink';
import MegaMenuProductContent from '@/app/navigation/main/MegaMenuProductContent';
import {productRequestPath} from '@/util/helper/path';
import {useDispatch} from 'react-redux';
import {setBackdropVisible} from '@/redux/slices/layoutSlice';

interface MegaMenuNavigationProps {
    page: INavigation;
    open: boolean;
    productRequestPage?: string;
    productOverviewPage?: string;
}

export default function MegaMenuNavigation(
    {
        page,
        open,
        productRequestPage,
        productOverviewPage
    }: MegaMenuNavigationProps) {
    const [activePage, setActivePage] = useState<INavigation | null>(null);
    const [activeSubPage, setActiveSubPage] = useState<INavigation | null>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (open) {
            dispatch(setBackdropVisible(true));
        }
    }, [open]);

    const handleMouseEnterLevel2 = (p: INavigation) => {
        setActivePage(p);
        setActiveSubPage(null);
    }

    const handleMouseEnterLevel3 = (p: INavigation) => {
        setActiveSubPage(p);
    }

    useEffect(() => {
        if (!open) {
            setActivePage(null);
            setActiveSubPage(null);
        }
    }, [open]);

    const megaMenuNavigationElement = (
        <AnimatePresence>
            {open && (
                <motion.div
                    className="absolute z-[-1] left-0 right-0 top-0 max-h-screen hidden lg:block"
                    initial={{opacity: 0}}
                    animate={{opacity: 1, transition: {duration: 0.3, ease: 'easeOut'}}}
                    exit={{opacity: 0, transition: {duration: 0.2, ease: 'easeOut'}}}
                >
                    <aside
                        className="bg-grey-100">

                        <div className="px-10">

                            <div className="flex -mx-8">

                                <div className="w-4/12 px-8 py-28 border-r border-grey-175">
                                    {(page.children && page.children.filter(p => p.visible).length) && (
                                        <ul>
                                            {/*level 2*/}
                                            {page.children.filter(p => p.visible).map((p, i) => (
                                                <li
                                                    key={i}
                                                    onMouseEnter={() => handleMouseEnterLevel2(p)}
                                                >
                                                    <MegaMenuNavigationLink
                                                        page={p}
                                                        withThumbnail={true}
                                                        isActive={activePage?.id === p.id}
                                                        forceChevronIcon={false}
                                                        productOverviewPage={productOverviewPage}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>


                                {(activePage?.children && activePage.children.filter(p => p.visible).length) ? (
                                    <div className="w-4/12 px-8 py-28 border-r border-grey-175">
                                        <ul>
                                            {/*level 3*/}
                                            {activePage.children.filter(p => p.visible).map((p, i) => (
                                                <li
                                                    key={i}
                                                    onMouseEnter={() => handleMouseEnterLevel3(p)}
                                                >
                                                    <MegaMenuNavigationLink
                                                        page={p}
                                                        withThumbnail={false}
                                                        isActive={activeSubPage?.id === p.id}
                                                        forceChevronIcon={p.id.startsWith('product_')}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                ) : (
                                    <div className="w-8/12 px-8 pt-28 pb-10">
                                        <MegaMenuSnippetContent
                                            title={page.customProperties.title ?? page.label}
                                            description={page.customProperties.teaserText ?? ''}
                                            images={page.customProperties.teaserImages}
                                        />
                                    </div>
                                )}

                                {activeSubPage && (
                                    <div className="w-4/12 px-8 py-28">
                                        <MegaMenuProductContent
                                            title={activeSubPage.customProperties.title ?? page.label}
                                            description={activeSubPage.customProperties.teaserText ?? ''}
                                            images={activeSubPage.customProperties.teaserImages ?? []}
                                            href={activeSubPage.href}
                                            productRequestHref={
                                                activeSubPage.id.startsWith('product_') && productRequestPage
                                                    ? productRequestPath(productRequestPage, activeSubPage.id.split('_').pop())
                                                    : null
                                            }
                                        />
                                    </div>
                                )}

                            </div>

                        </div>

                    </aside>
                </motion.div>
            )}
        </AnimatePresence>
    )

    return (megaMenuNavigationElement)
}
