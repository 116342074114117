import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import LoaderSvg from '@/images/icons/loader.svg';

interface LoaderProps {
    isLoading: boolean;
    wrapperClass: string;
}

export default function Loader({ isLoading, wrapperClass }: LoaderProps) {
    return (
        <AnimatePresence>
            {isLoading && (
                <motion.div
                    className={wrapperClass}
                    initial={{opacity: 0}}
                    animate={{opacity: 1, transition: {duration: 0.2, ease: 'easeOut'}}}
                    exit={{opacity: 0, transition: {duration: 0.2, ease: 'easeOut'}}}
                >
                    <LoaderSvg className="animate-spin w-6 h-auto mx-auto my-auto text-primary"/>
                </motion.div>
            )}
        </AnimatePresence>
    )
}
