import ChevronDownIcon from '@/images/icons/chevron-down.svg';
import {I18nActiveLanguages} from '@/types/i18n';

interface LanguageSwitcherProps {
    languages: I18nActiveLanguages[];
    inverted: boolean;
}

export default function LanguageSwitcher({languages, inverted}: LanguageSwitcherProps) {
    return (
        <div className="max-w-full w-48">
            <div className="relative whitespace-nowrap cursor-pointer group">

                <span className="inline-block font-bold">
                    <span className={`${inverted ? 'text-white opacity-[.64]' : 'text-blue-100'}`}>
                        {languages.find(l => l.active)?.titleNative}
                    </span>
                    <ChevronDownIcon className={`${inverted ? 'text-white' : 'text-blue-100'} ml-2 w-6 h-6 inline`}/>
                </span>

                <div className="absolute top-0 right-0 left-0 z-20 group-hover:block hidden">
                    <ul className="block overflow-hidden mt-1">

                        {languages.sort(la => la.active ? -1 : 1).map((l, i) => (
                            <li key={i} className="leading-none py-0 mt-0 block mb-1 w-full">

                                {l.active ? (
                                    <span
                                       className={`${inverted ? 'text-white' : 'text-blue-100'} py-0 my-0 block font-bold transition opacity-[.64] hover:opacity-[.94] ${l.active && 'opacity-[.94]'}`}>
                                        {l.titleNative}
                                    </span>
                                ) : (
                                    <a href={l.linkedHref} title={l.title}
                                       className={`${inverted ? 'text-white' : 'text-blue-100'} py-0 my-0 block font-bold transition opacity-[.64] hover:opacity-[.94] ${l.active && 'opacity-[.94]'}`}>
                                        {l.titleNative}
                                    </a>
                                )}

                            </li>
                        ))}

                    </ul>
                </div>

            </div>
        </div>
    )
}
