import React from 'react';

interface MegaMenuSnippetContentProps {
    title: string;
    description: string;
    images?: string[] | null;
}

export default function MegaMenuSnippetContent({title, description, images}: MegaMenuSnippetContentProps) {
    return (
        <div className="flex h-full -mx-2">

            <div className="w-5/12 px-2">
                <h5 className="text-blue-200">{title}</h5>
                <p>{description}</p>
            </div>

            <div className="w-7/12 px-2">
                {images && images.length > 0 && (
                    <div className="flex h-full -mx-2 gap-y-4 flex-wrap">
                        {images.map((image, i) => (
                            <div key={i} className="w-6/12 min-w-6/12 px-2">
                                <img src={image} className="rounded-lg h-full w-full object-cover object-center" alt={`${description} ${i.toString()}/${images.length.toString()}`} />
                            </div>
                        ))}
                    </div>
                )}
            </div>

        </div>
    )
}
