import React from 'react';
import useTranslations from '@/hooks/translations';
import MegaMenuProductSlider from '@/app/navigation/main/MegaMenuProductSlider';

interface MegaMenuSnippetContentProps {
    title: string;
    description: string;
    images: string[];
    href: string;
    productRequestHref: string | null;
}

export default function MegaMenuProductContent(
    {
        title,
        description,
        images,
        href,
        productRequestHref
    }: MegaMenuSnippetContentProps) {
    const {tc} = useTranslations();

    return (
        <div>
            {images.length > 1 ? (
                <MegaMenuProductSlider title={title} images={images}/>
            ) : images.length === 1 && (
                <img className="rounded-lg mb-6" alt={title} src={images[0]}/>
            )}
            <h5 className="text-blue-200">{title}</h5>
            <p>{description}</p>
            {productRequestHref && (
                <a
                    className="btn btn-primary mr-4 mb-4"
                    href={productRequestHref}>
                    {tc('app.navigation.product.request_now')}
                </a>
            )}
            <a className="btn btn-tertiary mr-4 mb-4" href={href}>{tc('app.navigation.product.more')}</a>
        </div>
    )
}
