'use client';

import React, {useEffect, useState} from 'react';
import ResultItem from '@/app/layout/search/ResultItem';
import {SearchHits} from '@/types/search';
import {getFullTextItems} from '@/api/search';
import useTranslations from '@/hooks/translations';
import Loader from '@/app/components/ui/Loader';

interface ResultsProps {
    initialHits: SearchHits[];
    isInGroup: boolean;
    itemCount: number;
    subType: string;
    label: string;
    searchQuery: string;
}

export default function Results({initialHits, isInGroup, itemCount, subType, label, searchQuery}: ResultsProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(isInGroup ? 1 : 2);
    const [hits, setHits] = useState<SearchHits[]>(initialHits);
    const {tc} = useTranslations();

    useEffect(() => {
        setHits(initialHits);
    }, [initialHits]);

    const loadMore = async (subType: string) => {
        setIsLoading(true);
        const results = await getFullTextItems(searchQuery, subType, page);
        if (isInGroup && page === 1) {
            setHits(results.hits);
        } else {
            setHits(prevHits => [...prevHits, ...results.hits]);
        }
        setPage(page + 1);
        setIsLoading(false);
    }

    if (!hits || hits.length < 1) {
        return <></>
    }

    return (
        <>
            <p className="text-sm font-bold text-blue-200 mb-4">{label} ({itemCount})</p>
            {hits && hits.map(item => (
                <ResultItem
                    key={item.id}
                    title={item.title}
                    description={'description' in item ? item.description : 'teaserText' in item ? item.teaserText : ''}
                    image={'teaserImage' in item ? item.teaserImage : null}
                    detailUrl={item.detailUrl}
                />
            ))}

            {hits && itemCount > hits.length && (
                <div className="flex">
                    <button onClick={() => loadMore(subType)}
                            className="btn btn-tertiary">{tc('app.search.load_more')}</button>
                    <Loader isLoading={isLoading} wrapperClass="justify-self-center self-center ml-4"/>
                </div>

            )}
        </>
    )
}
