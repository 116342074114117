import ChevronLeft from '@/images/icons/chevron-left.svg';
import ChevronRight from '@/images/icons/chevron-right.svg';
import { useSwiper } from 'swiper/react';

interface ProductSliderArrowsProps {
    loop: boolean;
    prevDisabled?: boolean;
    nextDisabled?: boolean;
}

export default function ProductSliderArrows({ loop, prevDisabled = false, nextDisabled = false }: ProductSliderArrowsProps) {
    const swiper = useSwiper();

    return (
        <div className="absolute inset-0 flex items-center justify-between px-2 md:px-4">
            <div className="z-10 absolute h-full w-30 bg-gradient-to-r from-black/30 to-transparent left-0 pointer-events-none"></div>
            <div className="z-10 absolute h-full w-30 bg-gradient-to-l from-black/30 to-transparent right-0 pointer-events-none"></div>
            <button className={`relative z-20 h-12 w-12 rounded-full u-center bg-white bg-opacity-20 ${(!loop && prevDisabled) ? 'pointer-events-none opacity-40' : 'transition-opacity ease-out duration-75 hover:bg-opacity-50'}`}
                    disabled={!loop && prevDisabled}
                    onClick={() => swiper.slidePrev()}>
                <ChevronLeft className="h-6 text-white" />
            </button>
            <button className={`relative z-20 h-12 w-12 rounded-full u-center bg-white bg-opacity-20 ${(!loop && nextDisabled) ? 'pointer-events-none opacity-40' : 'transition-opacity ease-out duration-75 hover:bg-opacity-50'}`}
                    disabled={!loop && nextDisabled}
                    onClick={() => swiper.slideNext()}>
                <ChevronRight className="h-6 text-white" />
            </button>
        </div>
    )
}
