'use client';

import {INavigation} from '@/app/navigation/Navigation.types';
import React from 'react';

import {AnimatePresence, motion} from 'framer-motion';
import MobileNavigationLink from '@/app/navigation/mobile/MobileNavigationLink';
import useTranslations from '@/hooks/translations';
import ChevronLeft from '@/images/icons/chevron-left.svg'
import LanguageSwitcher from '@/app/layout/LanguageSwitcher';
import {I18nActiveLanguages} from '@/types/i18n';

interface MobileNavigationPanelProps {
    open: boolean;
    pages: Array<INavigation>;
    navLvl: number;
    onClose?: () => void;
    languages: I18nActiveLanguages[];
    productOverviewPage?: string;
}

export default function MobileNavigationPanel({open, pages, navLvl, onClose, languages, productOverviewPage}: MobileNavigationPanelProps) {
    const {tc} = useTranslations();

    return (
        <AnimatePresence>
            {open && (
                <motion.div
                    className="fixed z-20 max-h-screen lg:hidden left-0 top-0 shadow-3xl"
                    initial={{translateX: navLvl === 1 ? '0' : '100%', opacity: 0}}
                    animate={{translateX: '0%', opacity: 1, transition: {duration: 0.25, ease: 'easeInOut'}}}
                    exit={{translateX: navLvl === 1 ? '0' : '100%', opacity: 0, transition: {duration: 0.2, ease: 'easeInOut'}}}
                >
                    <aside
                        className="relative z-20 bg-white inset-0 w-screen h-screen overflow-y-auto">

                        <div className="h-full py-20 sm:py-28 container flex flex-col content-between">

                            <div className="flex-grow">
                                {navLvl > 1 && (
                                    <button
                                        className="text-blue font-bold relative h-13 py-4 mb-2 block w-full text-left"
                                        onClick={() => onClose?.()}
                                    >
                                        <ChevronLeft className="w-6 h-auto inline-block -ml-1 mr-1 ty:-left-8 -mt-0.5"/>
                                        {tc('app.navigation.mobile.back')}
                                    </button>
                                )}

                                {pages.map((p, i) => (
                                    <MobileNavigationLink
                                        key={i}
                                        page={p}
                                        withThumbnail={pages.some(p => p.customProperties.thumbnail)}
                                        navLvl={navLvl}
                                        productOverviewPage={productOverviewPage}
                                        languages={languages}
                                    />
                                ))}
                            </div>

                            {languages.length > 1 && navLvl === 1 && (
                                <div className="px-4 justify-self-end">
                                    <LanguageSwitcher inverted={false} languages={languages}/>
                                </div>
                            )}

                        </div>

                    </aside>
                </motion.div>
            )}
        </AnimatePresence>
    )
}
