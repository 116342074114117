'use client'

import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import ProductSliderArrows from '@/app/components/product/ProductSliderArrows';

interface MegaMenuProductSliderProps {
    title: string;
    images: string[];
}

export default function MegaMenuProductSlider({title, images}: MegaMenuProductSliderProps) {

    return (
        <Swiper
            loop={true}
            slidesPerView={1}
            spaceBetween={0}
            className="w-full rounded-lg mb-6"
        >

            <ProductSliderArrows loop={true}/>

            {images.map((item, i) => (
                <SwiperSlide
                    key={i}
                    className="relative">
                    <img
                        src={item}
                        className="w-full h-auto"
                        alt={`${title} ${i.toString()}/${images.length.toString()}`}
                    />
                </SwiperSlide>
            ))}

        </Swiper>
    )
}
