'use client';

import {INavigation} from '@/app/navigation/Navigation.types';
import ChevronRight from '@/images/icons/chevron-right.svg';
import {useState} from 'react';
import FlyOutNavigation from '@/app/navigation/main/FlyOutNavigation';
import Link from "next/link";
import {usePathname} from "next/navigation";

interface MainNavigationProps {
    page: INavigation;
    navLvl: number;
}

export default function FlyOutNavigationItem({page, navLvl}: MainNavigationProps) {
    const pathname = usePathname();
    const [open, setOpen] = useState<boolean>(false);
    const hasChildren = page.children && page.children.filter(p => p.visible).length;

    const handleMouseEnter = () => {
        if (!hasChildren) {
            return;
        }
        setOpen(true);
    }

    const handleMouseLeave = () => {
        if (!hasChildren) {
            return;
        }
        setOpen(false);
    }

    return (
        <li
            className="group relative"
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
        >
            <Link href={page.href}
                  className={`group relative inline-block w-full text-sm font-bold py-3.5 pl-4 pr-20 hover:text-blue-100 focus:text-blue-100 transition ${new RegExp(`^${page.href}.*$`).test(pathname) ? 'text-blue-100' : 'text-blue-200'}`}
                  title={page.label}
            >
                <div className={'w-full'}>
                    {page.label}
                </div>
                {hasChildren ? (
                    <ChevronRight
                        className={`w-6 block absolute right-4 top-3 text-blue-100`}/>
                ) : <></>}
            </Link>

            {(page.children && page.children.filter(p => p.visible).length) ? (
                <FlyOutNavigation pages={page.children.filter(p => p.visible)} open={open} navLvl={navLvl + 1}/>
            ) : <></>}

        </li>
    )
}
