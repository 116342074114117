'use client';

import {useEffect} from 'react';
import {useParams} from 'next/navigation';
import {I18nContext} from '@/types/i18n';

interface LayoutHelperProps {
    i18nContext: I18nContext | null;
}


export default function LayoutHelper({i18nContext}: LayoutHelperProps) {
    const {lang} = useParams();

    const resizeObserverHeader = new ResizeObserver((entries) => {
        for (let entry of entries) {

            if (entry.target !== document.getElementById('page-header')) {
                continue;
            }

            const root = document.documentElement;
            root.style.setProperty('--header-height', `${entry.contentRect.height}px`);
        }
    });

    // set header height css var
    useEffect(() => {
        const pageHeader = document.getElementById('page-header');

        if (pageHeader) {
            resizeObserverHeader.observe(pageHeader);
        }

    }, []);

    // set html lang attribute
    useEffect(() => {
        document.documentElement.lang = lang instanceof Array ? lang[0] : lang;
    }, [lang]);

    return null;
}
