export const productRequestPath = (productRequestPage: string, productId?: number | string) => {
    if (!productId) {
        return productRequestPage;
    }
    return `${productRequestPage}?productId=${productId.toString()}`
};

export const productCategoryPath = (productOverviewPage: string, categoryId?: number | string) => {
    if (!categoryId) {
        return productOverviewPage;
    }
    return `${productOverviewPage}?&filter[category]=${categoryId}`;
};
