'use client';

import {INavigation} from '@/app/navigation/Navigation.types';
import React, {useEffect} from 'react';
import {usePathname, useSearchParams} from 'next/navigation';

import {createPortal} from 'react-dom';
import {AnimatePresence, motion} from 'framer-motion';
import MobileNavigationPanel from '@/app/navigation/mobile/MobileNavigationPanel';
import {useAppSelector} from '@/redux/hooks';
import {useDispatch} from 'react-redux';
import {setMobileNavigationVisible} from '@/redux/slices/layoutSlice';
import {I18nActiveLanguages} from '@/types/i18n';


interface MobileNavigationProps {
    pages: Array<INavigation>;
    languages: I18nActiveLanguages[];
    productOverviewPage?: string;
}

export default function MobileNavigation({pages, languages, productOverviewPage}: MobileNavigationProps) {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const open = useAppSelector(state => state.layout).mobileNavigationVisible;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setMobileNavigationVisible(false));
    }, [pathname, searchParams]);


    useEffect(() => {
        if (open) {
            document.documentElement.classList.add('overflow-y-hidden');
        } else {
            document.documentElement.classList.remove('overflow-y-hidden');
        }
    }, [open]);

    return createPortal(
        <AnimatePresence>
            {open && (
                <motion.div
                    className="fixed z-20 max-h-screen lg:hidden"
                    initial={{opacity: 0}}
                    animate={{opacity: 1, transition: {duration: 0.3, ease: 'easeOut'}}}
                    exit={{opacity: 0, transition: {duration: 0.2, ease: 'easeOut'}}}
                >

                    <MobileNavigationPanel open={true} pages={pages} navLvl={1} languages={languages} productOverviewPage={productOverviewPage} />

                </motion.div>
            )}
        </AnimatePresence>, document.body)
}
