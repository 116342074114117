'use client';

import {PimcoreImage as PimcoreImageType} from '@/types/pimcore';
import React, {MouseEventHandler} from 'react';

interface PimcoreImageProps {
    image: PimcoreImageType;
    className?: string;
    withCaption?: boolean;
    withLightbox?: boolean;
    setLightboxOpen?: (open: boolean) => void;
    invertedCaption?: boolean;
    onMouseEnter?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
    onMouseLeave?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
    onMouseMove?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
}

export default function PimcoreImage({
                                         image,
                                         className,
                                         setLightboxOpen,
                                         withLightbox,
                                         withCaption,
                                         invertedCaption,
                                         onMouseLeave,
                                         onMouseEnter,
                                         onMouseMove,
                                     }: PimcoreImageProps) {
    if (!!image?.mediaList?.length) {
        return (
            <>
                <picture>
                    {image.mediaList.map((media, index) => (
                        <source key={index} {...(media.media ? {media: media.media} : {})} srcSet={media.srcset}
                                width={media.width} height={media.height}/>
                    ))}
                    <img loading="lazy"
                         src={image.path || ''}
                         alt={image.title || ''}
                         width={image.mediaList[0].width}
                         height={image.mediaList[0].height}
                         className={`${className} ${withLightbox ? 'cursor-pointer' : ''}`}
                         {...(onMouseEnter ? {onMouseEnter} : {})}
                         {...(onMouseLeave ? {onMouseLeave} : {})}
                         {...(onMouseMove ? {onMouseMove} : {})}
                         onClick={withLightbox ?
                             () => setLightboxOpen ? setLightboxOpen(true) : {} :
                             () => {
                             }
                         }
                         srcSet={image.mediaList.map(media => media.srcset).join(', ')}
                    />
                </picture>

                {(withCaption && image.description) && (
                    <span className={`w-full mt-2 block text-description ${invertedCaption ? 'text-white' : 'text-grey-400'}`}>
                        {image.description}
                    </span>
                )}
            </>
        )
    } else if (image.path) {
        return (
            <img
                alt={image.title || ''}
                className={`${className} ${withLightbox ? 'cursor-pointer' : ''}`}
                onClick={withLightbox ?
                    () => setLightboxOpen ? setLightboxOpen(true) : {} :
                    () => {
                    }
                }
                src={image.path}
            />
        )
    }
}
