'use client';

import React from 'react';
import Link from 'next/link';
import InternalLinkIcon from '@/images/icons/link-internal.svg';
import {PimcoreImage as PimcoreImageType} from '@/types/pimcore';
import PimcoreImage from '@/app/components/cms/content/PimcoreImage/PimcoreImage';

interface ResultsItemProps {
    title: string;
    description: string;
    detailUrl: string;
    image: PimcoreImageType | null;
    exclusive?: boolean;
}

export default function ResultItem({title, description, detailUrl, image, exclusive}: ResultsItemProps) {
    return (
        <Link className="block u-border-gradient-landscape group mb-4"
              title={title}
              href={detailUrl}
              target="_blank">
            <div className="flex flex-nowrap items-center p-2 md:p-6 group-hover:gradient-hover-background">
                {image && (
                    <div className="w-20 min-w-20 mr-2 md:mr-6">
                        <PimcoreImage
                            image={image}
                            withLightbox={false}
                            withCaption={false}
                            className="w-full h-auto rounded-lg"
                        />
                    </div>
                )}
                <div className="flex-grow flex flex-col">
                    <h5 className="font-bold mb-0 h5 text-blue-200 group-hover:text-primary-text hyphens-auto">
                        {title}
                    </h5>
                    {description && (
                        <div className="small mt-2 line-clamp-2">
                            {description}
                        </div>
                    )}
                </div>
                <div className="w-11">
                    <div className="w-11 p-3 rounded-full bg-grey-125 transition-all group-hover:bg-grey-175">
                        <InternalLinkIcon className="text-primary-text w-5 h-5"/>
                    </div>
                </div>

            </div>
        </Link>
    )
}
