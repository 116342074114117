'use client';

import BurgerOpen from '@/images/icons/burger-open.svg';
import BurgerClose from '@/images/icons/close.svg';
import {useDispatch} from 'react-redux';
import {useAppSelector} from '@/redux/hooks';
import {setMobileNavigationVisible} from '@/redux/slices/layoutSlice';

export default function MobileNavigationTrigger() {
    const mobileNavigationVisible = useAppSelector(state => state.layout).mobileNavigationVisible;
    const dispatch = useDispatch();

    const toggle = () => {
        dispatch(setMobileNavigationVisible(!mobileNavigationVisible));
    };

    return (
        <button
            type="button"
            className="h-full w-14 sm:w-16 relative z-40 bg-primary rounded-br-lg flex items-center justify-center"
            onClick={() => toggle()}>
            {mobileNavigationVisible ? (
                <BurgerClose className="w-6 h-6 burger-button"/>
            ) : (
                <BurgerOpen className="w-6 h-6 burger-button"/>
            )}
        </button>
    )
}
